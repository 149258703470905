
  import teaseredArticles from '@/apollo/queries/article/teaseredArticles.gql';
  import {shuffleArray} from '@/helper/ArrayHelper';
  import {prepareImageUrl} from '@/helper/ImageHelper';
  import ArticleCard from '@/components/elements/ArticleCard';

  export default {
    components: {
      ArticleCard,
    },
    filters: {
      truncate(string, value) {
        return string.length > value
          ? string.substring(0, value) + '…'
          : string;
      },
    },
    props: {
      numberOfCards: {
        type: Number,
        required: true,
        default: 2,
      },
      columnsNumber: {
        type: Number,
        required: true,
        default: 6,
      },
      fillHeight: {
        type: Boolean,
        required: false,
        default: true,
      },
      headlineType: {
        type: Number,
        required: false,
        default: 2,
      },
      contentScope: {
        type: String,
        required: false,
        default: 'promotionbasis_candidate',
      },
      type: {
        type: String,
        required: false,
        default: 'article-listing',
      },
      isInDetails: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        teaseredArticles: [],
      };
    },
    apollo: {
      teaseredArticles: {
        query: teaseredArticles,
        variables() {
          return {
            scope: this.contentScope,
          };
        },
        result({data}) {
          // Apollo calls this function multiple times, on the server and on the client at least once
          // If it's the first visit to this page then it will call it again on the client
          // If the client was on this page already then a cache exists and will be called before the actual network request is made
          // stale false indicates that this is the actual network call
          // @see https://github.com/vuejs/vue-apollo/discussions/492
          if (data.teaseredArticles.length > 0) {
            // Reduce and shuffle teasered articles
            this.teaseredArticles = shuffleArray(data.teaseredArticles)
              .slice(0, this.numberOfCards)
              .sort((a, b) => (a.title > b.title ? 1 : -1));
          }
          this.loading = false;
        },
      },
    },
    /*
    mounted() {
      //console.log('this.contentScope', this.contentScope);

      this.loadArticles().catch((e) => {
        this.teaseredArticles = [];
        this.loading = false;

        throw e;
      });
    },
    */
    methods: {
      async loadArticles() {
        // Query for teasered articles
        const teaseredArticles = await this.$cachedStrapi.find('articles', {
          scope_contains: this.contentScope,
          article_category_null: false,
          teasered: true,
        });

        // Reduce and shuffle teasered articles
        this.teaseredArticles = shuffleArray(teaseredArticles)
          .slice(0, this.numberOfCards)
          .sort((a, b) => (a.title > b.title ? 1 : -1));
        this.loading = false;
      },
      imageUrl(article) {
        return article.card_image
          ? prepareImageUrl(article.card_image.url)
          : require(process.env.NUXT_ENV_FALLBACK_CARD_IMAGE);
      },
    },
  };
